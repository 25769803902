import { useState } from "react";
import { ProgressBar } from "react-step-progress-bar";
import "../../assets/stylings/Opportunities_styling.css";

import propertyImage from "../../../User/assets/images/property_image.png";
import ContactUsBanner from "../Accessories/ContactUsBanner";
import PageRoutesBar from "../Accessories/PageRoutesBar";

const Opportunities = () => {
  const [amountRaised, setAmountRaised] = useState(1132550);

  const [amountGoal, setAmountGoal] = useState(100000000);

  const [progress, setProgress] = useState((amountRaised / amountGoal) * 100);

  return (
    <>
      {/* <PageRoutesBar /> */}
      <div className="opportunities_page">
        {/* <div className="heading_texts">
          <h1 className="main_title">Browse Opportunities</h1>
          <p className="sub_title">
            Real Estate Companies in Nigeria raising money for property
            development.
          </p>
        </div> */}
        <div className="listing_container">
          <div className="card">
            <div className="image_container">
              <img
                src={propertyImage}
                alt=""
                style={{ borderRadius: "14px 14px 0 0" }}
              />
              <p className="absolutely_placed">Closing Soon</p>
            </div>
            <div className="other_parts">
              <div className="time_left">
                Time Left:<span id="tab"></span> 5 <span>Days </span> : 5{" "}
                <span> Hours </span> : 8<span> Mins </span> : 26{" "}
                <span> Secs </span>
              </div>
              <div className="more_info">
                <div className="raised_amount_display">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      gap: "4%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "ApercuProMediumNormal",
                        fontSize: "36px",
                        lineHeight: "56px",
                        letterSpacing: "-0.03em",
                      }}
                    >
                      ₦{amountRaised.toString()}
                    </div>
                    <p
                      style={{
                        color: "#ff0000",
                        fontSize: "13px",
                        lineHeight: "16.16px",
                        marginBottom: "10px",
                      }}
                    >
                      Raised
                    </p>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "8px",
                      borderRadius: "4px",
                      backgroundColor: "#00D7A3",
                      padding: "2px",
                      margin: "12px 0px",
                    }}
                  >
                    <ProgressBar
                      percent={progress}
                      filledBackground="#fff"
                      height={4}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3%",
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#ff0000",
                        color: "#fff",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        lineHeight: "17.4px",
                        maxWidth: "50px",
                      }}
                    >
                      Goal
                    </p>
                    <div
                      style={{
                        fontFamily: "ApercuProMediumNormal",
                      }}
                    >
                      ₦{amountGoal.toString()}
                    </div>
                  </div>
                </div>
                <div className="other_texts">
                  <p className="property_name">2 Bedroom Serviced Apartments</p>
                  <div className="investment_desc">
                    <div className="investment_property">
                      <p className="param">Minimum Investment</p>
                      <p className="value">₦10,000</p>
                    </div>
                    <div className="investment_property">
                      <p className="param">Return On Investment</p>
                      <p className="value">20%</p>
                    </div>
                  </div>
                </div>
                <button className="pry_button">Invest Now</button>
              </div>
            </div>
          </div>
        </div>
        {/* <section
          style={{ marginBottom: "150px" }}
          className="main_third_section"
        >
          <ContactUsBanner />
        </section> */}
      </div>
    </>
  );
};

export default Opportunities;
