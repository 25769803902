import React from "react";
import {Context} from "./../Context";
import {apiBaseUrl} from "./../../../../config";
import $ from "jquery";
import _ from "lodash";
import Hook from "./Hook";
import User from "./../../../User/Components/Utils";

export default class Api extends React.Component {
    constructor(props) {
        super(props);
        Api.contextType = Context;
    }

    static baseUrl = apiBaseUrl;

    static list = {
        waitlist: {method: "post", url: "wait-list"},
        announcement: {method: "get", url: "customer-announcements"},
        "bank.pay": {method: "post", url: "manual-payments"},
        "blog.article.list": {method: "get", url: "blog-list"},
        "blog.article.related": {method: "get", url: "related-blog"},
        "blog.article.view": {method: "get", url: "single-blog"},
        contact: {method: "post", url: "send-us-feedback"},
        "conversation.list": {method: "get", url: "customer-messages"},
        "conversation.message.list": {
            method: "get",
            url: "customer-message-conversations",
        },
        "conversation.delete": {
            method: "delete",
            url: "customer-delete-conversation",
        },
        "conversation.message.add": {
            method: "post",
            url: "customer-message-reply",
        },
        "conversation.read": {method: "get", url: "mark-message-read"},
        "developer.agency.edit": {method: "put", url: "edit-developer-agency"},
        "developer.company.add": {method: "post", url: "setup-developer-company"},
        "developer.company.edit": {method: "put", url: "edit-developer-company"},
        "developer.company.follow": {method: "post", url: "follow-company"},
        "developer.company.following": {method: "get", url: "check-company"},
        "developer.company.funded.list": {
            method: "get",
            url: "funded-company-list",
        },
        "developer.company.opportunity.list": {
            method: "get",
            url: "company-opportunities",
        },
        "developer.company.property.list": {
            method: "get",
            url: "company-properties",
        },
        "developer.company.review.add": {
            method: "post",
            url: "rating-and-reviews",
        },
        "developer.company.review.list": {
            method: "get",
            url: "rating-and-reviews",
        },
        "developer.company.unfollow": {method: "post", url: "follow-company"},
        "developer.company.view": {method: "get", url: "company-profile"},
        "developer.list": {method: "get", url: "developer/list"},
        "developer.top.list": {method: "get", url: "top-developer-list"},
        "developer.opportunity.list": {
            method: "get",
            url: "customer-opportunities",
        },
        "email.subscribe": {method: "post", url: "subscriber/add"},
        "faq.list": {method: "get", url: "faqs"},
        "file.upload": {method: "post", url: "upload/media/file"},
        "knowledge.list": {method: "get", url: "knowledge-base-list"},
        "knowledge.view": {method: "get", url: "single-knowledge-base"},
        "dispute.list": {method: "get", url: "dispute-resolution-list"},
        "dispute.view": {method: "get", url: "single-dispute-resolution"},
        "investment.add": {method: "post", url: "customer-pool-funding"},
        "investment.list.opportunity": {
            method: "get",
            url: "opportunity-investments",
        },
        "investment.list.pool": {method: "get", url: "customer-pool-funding"},
        "investment.setting.list": {method: "get", url: "pool-settings"},
        "investor.opportunity.list": {
            method: "get",
            url: "investor-funded-opportunities",
        },
        "locale.country.list": {method: "get", url: "country/list"},
        "locale.state.list": {method: "get", url: "state/list"},
        "marketing.add": {method: "post", url: "real-estate-service"},
        "notification.list": {method: "get", url: "customer-notifications"},
        "notification.read": {method: "post", url: "notification/read"},
        "notification.delete": {method: "delete", url: "notification/delete"},
        "opportunity.close": {method: "post", url: "close-opportunity"},
        "opportunity.investment.add": {
            method: "post",
            url: "customer-investments",
        },
        "opportunity.investment.constant.list": {
            method: "get",
            url: "investment-constant",
        },
        "opportunity.investment.delete": {
            method: "delete",
            url: "customer-investments/:id",
        },
        "opportunity.investment.cancel": {
            method: "post",
            url: "cancel-investment",
        },
        "opportunity.investment.list": {
            method: "get",
            url: "customer-investments",
        },
        "opportunity.investment.update.delete": {
            method: "get",
            url: "mark-funding-update-hide",
        },
        "opportunity.investment.update.list": {
            method: "get",
            url: "funding-update",
        },
        "opportunity.investment.update.read": {
            method: "get",
            url: "mark-funding-update-read",
        },
        "opportunity.list": {method: "get", url: "opportunity/list"},
        "opportunity.view": {method: "get", url: "single-opportunity"},
        "opportunity.project.update": {method: "get", url: "opportunity-project-update"},
        "opportunity.add": {method: "post", url: "customer-opportunities"},
        "opportunity.constant.list": {
            method: "get",
            url: "opportunities-constant",
        },
        "payment.bank.list": {method: "get", url: "bank/list"},
        "payment.callback.paystack": {
            method: "post",
            url: "payment/callback/paystack",
        },
        "payment.callback.rave": {method: "post", url: "payment/callback/rave"},
        "payment.callback.wallet": {
            method: "post",
            url: "payment/callback/wallet",
        },
        "payment.user.edit": {method: "post", url: "customer-bank-account"},
        "payment.user.view": {method: "get", url: "customer-bank-account"},
        "property.add": {method: "post", url: "customer-properties"},
        "property.category.list": {method: "get", url: "property-categories"},
        "property.constant.list": {method: "get", url: "property-deal-constants"},
        "property.feature.list": {method: "get", url: "property-features"},
        "property.list": {method: "get", url: "property/list"},
        "property.purchase.accept": {
            method: "post",
            url: "property/co-buy/accept",
        },
        "property.purchase.add": {method: "post", url: "customer-property-deals"},
        "property.purchase.list": {
            method: "get",
            url: "developer-property-deals",
        },
        "property.purchase.payment.list": {
            method: "get",
            url: "property-payment-schedules",
        },
        "property.user.list": {method: "get", url: "customer-properties"},
        "property.user.purchase.list": {
            method: "get",
            url: "customer-property-deals",
        },
        "property.view": {method: "get", url: "single-property"},
        "research.list": {method: "get", url: "article-and-research-list"},
        "setting.list": {method: "get", url: "general-settings"},
        "support.constant.list": {method: "get", url: "ticket-constant"},
        "support.ticket.add": {method: "post", url: "tickets"},
        "support.ticket.reply.add": {method: "post", url: "ticket-comment-add"},
        "support.ticket.reply.list": {method: "get", url: "ticket-comments"},
        "support.ticket.list": {method: "get", url: "tickets"},
        "support.ticket.view": {method: "get", url: "tickets/:id"},
        "support.portal.admin": {method: "get", url: "ticket-admin-availability"},
        "tour.add": {method: "post", url: "virtual-tour"},
        "tour.view": {method: "get", url: "virtual-tour"},
        "user.auth.login": {method: "post", url: "login"},
        "user.auth.register": {method: "post", url: "user-registration"},
        "user.auth.social": {method: "post", url: "social/auth"},
        "user.auth.verify": {method: "post", url: "user-verification"},
        "user.auth.verify.request": {
            method: "post",
            url: "user-verification-request",
        },
        "user.constant.list": {method: "get", url: "customer-constants"},
        "user.edit": {method: "put", url: "profile-update"},
        "user.find": {method: "get", url: "find-user"},
        "user.password.change": {method: "post", url: "user/password/change"},
        "user.password.reset": {method: "post", url: "password/reset"},
        "user.password.reset.confirm": {
            method: "post",
            url: "confirm/reset/code",
        },
        "user.password.reset.request": {
            method: "post",
            url: "password/reset/request",
        },
        "user.view": {method: "get", url: "user"},
        "wallet.pay": {method: "post", url: "wallet-pay"},
        "wallet.transaction.list": {method: "get", url: "wallet-transactions"},
        "referral.list": {method: "get", url: "referral-list"},
        "referral.settlement.list": {
            method: "get",
            url: "referral-settlement-list",
        },
    };

    static queryStringToObj = (queryString) => {
        let obj = {};
        queryString = String(queryString).replace(/^\?/, "");
        if (queryString.length) {
            let data = queryString.split("&");
            for (let i in data) {
                if (data.hasOwnProperty(i)) {
                    let field = data[i].split("=");
                    _.set(obj, field[0], decodeURIComponent(field[1]));
                }
            }
        }
        return obj;
    };

    static baseUrl = apiBaseUrl;

    static list = {
        waitlist: {method: "post", url: "wait-list"},
        announcement: {method: "get", url: "customer-announcements"},
        "bank.pay": {method: "post", url: "manual-payments"},
        "blog.article.list": {method: "get", url: "blog-list"},
        "blog.article.related": {method: "get", url: "related-blog"},
        "blog.article.view": {method: "get", url: "single-blog"},
        contact: {method: "post", url: "send-us-feedback"},
        "conversation.list": {method: "get", url: "customer-messages"},
        "conversation.message.list": {
            method: "get",
            url: "customer-message-conversations",
        },
        "conversation.delete": {
            method: "delete",
            url: "customer-delete-conversation",
        },
        "conversation.message.add": {
            method: "post",
            url: "customer-message-reply",
        },
        "conversation.read": {method: "get", url: "mark-message-read"},
        "developer.agency.edit": {method: "post", url: "edit-developer-agency"},
        "developer.company.add": {method: "post", url: "setup-developer-company"},
        "developer.company.edit": {method: "post", url: "edit-developer-company"},
        "developer.company.follow": {method: "post", url: "follow-company"},
        "developer.company.following": {method: "get", url: "check-company"},
        "developer.company.funded.list": {
            method: "get",
            url: "funded-company-list",
        },
        "developer.company.opportunity.list": {
            method: "get",
            url: "company-opportunities",
        },
        "developer.company.property.list": {
            method: "get",
            url: "company-properties",
        },
        "developer.company.review.add": {
            method: "post",
            url: "rating-and-reviews",
        },
        "developer.company.review.list": {
            method: "get",
            url: "rating-and-reviews",
        },
        "developer.company.unfollow": {method: "post", url: "follow-company"},
        "developer.company.view": {method: "get", url: "company-profile"},
        "developer.list": {method: "get", url: "developer/list"},
        "developer.top.list": {method: "get", url: "top-developer-list"},
        "developer.opportunity.list": {
            method: "get",
            url: "customer-opportunities",
        },
        "email.subscribe": {method: "post", url: "subscriber/add"},
        "faq.list": {method: "get", url: "faqs"},
        "file.upload": {method: "post", url: "upload/media/file"},
        "knowledge.list": {method: "get", url: "knowledge-base-list"},
        "knowledge.view": {method: "get", url: "single-knowledge-base"},
        "dispute.list": {method: "get", url: "dispute-resolution-list"},
        "dispute.view": {method: "get", url: "single-dispute-resolution"},
        "investment.add": {method: "post", url: "customer-pool-funding"},
        "investment.list.opportunity": {
            method: "get",
            url: "opportunity-investments",
        },
        "investment.list.pool": {method: "get", url: "customer-pool-funding"},
        "investment.setting.list": {method: "get", url: "pool-settings"},
        "investor.opportunity.list": {
            method: "get",
            url: "investor-funded-opportunities",
        },
        "locale.country.list": {method: "get", url: "country/list"},
        "locale.state.list": {method: "get", url: "state/list"},
        "marketing.add": {method: "post", url: "real-estate-service"},
        "notification.list": {method: "get", url: "customer-notifications"},
        "notification.read": {method: "post", url: "notification/read"},
        "notification.delete": {method: "delete", url: "notification/delete"},
        "opportunity.close": {method: "post", url: "close-opportunity"},
        "opportunity.investment.add": {
            method: "post",
            url: "customer-investments",
        },
        "opportunity.investment.constant.list": {
            method: "get",
            url: "investment-constant",
        },
        "opportunity.investment.delete": {
            method: "delete",
            url: "customer-investments/:id",
        },
        "opportunity.investment.cancel": {
            method: "post",
            url: "cancel-investment",
        },
        "opportunity.investment.list": {
            method: "get",
            url: "customer-investments",
        },
        "opportunity.investment.update.delete": {
            method: "get",
            url: "mark-funding-update-hide",
        },
        "opportunity.investment.update.list": {
            method: "get",
            url: "funding-update",
        },
        "opportunity.investment.update.read": {
            method: "get",
            url: "mark-funding-update-read",
        },
        "opportunity.list": {method: "get", url: "opportunity/list"},
        "opportunity.view": {method: "get", url: "single-opportunity"},
        "opportunity.add": {method: "post", url: "customer-opportunities"},
        "opportunity.project.update": {method: "get", url: "opportunity-project-update"},
        "opportunity.constant.list": {
            method: "get",
            url: "opportunities-constant",
        },
        "payment.bank.list": {method: "get", url: "bank/list"},
        "payment.callback.paystack": {
            method: "post",
            url: "payment/callback/paystack",
        },
        "payment.callback.rave": {method: "post", url: "payment/callback/rave"},
        "payment.callback.wallet": {
            method: "post",
            url: "payment/callback/wallet",
        },
        "payment.user.edit": {method: "post", url: "customer-bank-account"},
        "payment.withdrawal.request": {method: "post", url: "transaction-withdrawal"},
        "payment.transaction.pin": {method: "post", url: "set-transaction-pin"},
        "payment.user.view": {method: "get", url: "customer-bank-account"},
        "property.add": {method: "post", url: "customer-properties"},
        "property.category.list": {method: "get", url: "property-categories"},
        "property.constant.list": {method: "get", url: "property-deal-constants"},
        "property.feature.list": {method: "get", url: "property-features"},
        "property.list": {method: "get", url: "property/list"},
        "property.purchase.accept": {
            method: "post",
            url: "property/co-buy/accept",
        },
        "property.purchase.add": {method: "post", url: "customer-property-deals"},
        "property.purchase.list": {
            method: "get",
            url: "developer-property-deals",
        },
        "property.purchase.payment.list": {
            method: "get",
            url: "property-payment-schedules",
        },
        "property.user.list": {method: "get", url: "customer-properties"},
        "property.user.purchase.list": {
            method: "get",
            url: "customer-property-deals",
        },
        "property.view": {method: "get", url: "single-property"},
        "research.list": {method: "get", url: "article-and-research-list"},
        "setting.list": {method: "get", url: "general-settings"},
        "support.constant.list": {method: "get", url: "ticket-constant"},
        "support.ticket.add": {method: "post", url: "tickets"},
        "support.ticket.reply.add": {method: "post", url: "ticket-comment-add"},
        "support.ticket.reply.list": {method: "get", url: "ticket-comments"},
        "support.ticket.list": {method: "get", url: "tickets"},
        "support.ticket.view": {method: "get", url: "tickets/:id"},
        "support.portal.admin": {method: "get", url: "ticket-admin-availability"},
        "tour.add": {method: "post", url: "virtual-tour"},
        "tour.view": {method: "get", url: "virtual-tour"},
        "user.auth.login": {method: "post", url: "login"},
        "user.auth.register": {method: "post", url: "user-registration"},
        "user.auth.social": {method: "post", url: "social/auth"},
        "user.auth.verify": {method: "post", url: "user-verification"},
        "user.auth.verify.request": {
            method: "post",
            url: "user-verification-request",
        },
        "user.constant.list": {method: "get", url: "customer-constants"},
        "user.edit": {method: "post", url: "profile-update"},
        "user.find": {method: "get", url: "find-user"},
        "user.password.change": {method: "post", url: "user/password/change"},
        "user.password.reset": {method: "post", url: "password/reset"},
        "user.password.reset.confirm": {
            method: "post",
            url: "confirm/reset/code",
        },
        "user.password.reset.request": {
            method: "post",
            url: "password/reset/request",
        },
        "user.view": {method: "get", url: "user"},
        "wallet.pay": {method: "post", url: "wallet-pay"},
        "wallet.transaction.list": {method: "get", url: "wallet-transactions"},
        "referral.list": {method: "get", url: "referral-list"},
        "referral.settlement.list": {
            method: "get",
            url: "referral-settlement-list",
        },
    };

    // static queryStringToObj = (queryString) => {
    //   let obj = {};
    //   queryString = String(queryString).replace(/^\?/, "");
    //   if (queryString.length) {
    //     let data = queryString.split("&");
    //     for (let i in data) {
    //       if (data.hasOwnProperty(i)) {
    //         let field = data[i].split("=");
    //         _.set(obj, field[0], decodeURIComponent(field[1]));
    //       }
    //     }
    //   }
    //   //   }
    //   // }
    //   //  else if (prefix) {
    //   //   fields.push(prefix + "=");
    //   // }
    //   // return fields.join("&");
    // };

    static fetch = (id, params, data, headers, uploadProgress) => {
        let csrfToken = $('meta[name="csrf-token"]').attr("content");
        let defaultHeaders = {};
        if (csrfToken) {
            defaultHeaders["X-CSRF-TOKEN"] = csrfToken;
        }
        const user = User.getUser();
        if (typeof user === "object" && user.token) {
            defaultHeaders["Authorization"] = "Bearer " + user.token;
        }
        headers =
            typeof headers === "object"
                ? {...defaultHeaders, ...headers}
                : defaultHeaders;
        return new Promise((resolve, reject) => {
            if (Api.list[id]) {
                let mockResponse = Hook.fire("api.mock.response", undefined, [
                    id,
                    params,
                    data,
                ]);
                if (mockResponse) {
                    return resolve({
                        responseText: mockResponse,
                        responseJSON: JSON.parse(mockResponse),
                        textStatus: "success",
                        status: 200,
                    });
                }
                let url = Api.list[id].url;
                let method = Api.list[id].method.toLowerCase();
                if (typeof params === "object") {
                    for (let i in params) {
                        if (params.hasOwnProperty(i)) {
                            let regex = new RegExp(":" + i, "gi");
                            url = url.replace(regex, params[i]);
                        }
                    }
                }
                $.ajax({
                    ...(data && data.constructor.name === "FormData"
                        ? {
                            processData: false,
                            contentType: false,
                        }
                        : {}),
                    ...{
                        method: method,
                        url: Api.baseUrl + url,
                        headers: headers,
                        data: data,
                        crossDomain: true,
                        xhr: function () {
                            const xhr = new window.XMLHttpRequest();
                            xhr.upload.addEventListener(
                                "progress",
                                (e) => {
                                    if (e.lengthComputable) {
                                        if (typeof uploadProgress === "function") {
                                            uploadProgress(e.loaded / e.total);
                                        }
                                    }
                                },
                                false
                            );
                            return xhr;
                        },
                        success: (data, textStatus, jqXHR) => {
                            resolve(
                                JSON.parse(
                                    JSON.stringify({
                                        ...jqXHR,
                                        ...{data},
                                        ...{textStatus},
                                        ...{errorThrown: null},
                                    })
                                )
                            );
                        },
                        error: (error) => {
                            reject(error);
                        },
                    },
                });
            }
        });
    };
}
