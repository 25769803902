import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "./Templates/Layout";
import PropCrowdy from "./Pages/PropCrowdy";
import UserDashboard from "./../../User/Components/Pages/Dashboard";
import UserRegister from "./../../User/Components/Pages/Register";
import UserLogin from "./../../User/Components/Pages/Login";
import UserVerify from "./../../User/Components/Pages/Verify";
import UserForgot from "./../../User/Components/Pages/Forgot";
import UserAccount from "./../../User/Components/Pages/Account";
import UserLogout from "./../../User/Components/Pages/Logout";
import How from "./Pages/How";
import OpportunityList from "./../../Opportunity/Components/Pages/List";
import OpportunityView from "./../../Opportunity/Components/Pages/View";
import OpportunityAdd from "./../../Opportunity/Components/Pages/Add";
import OpportunityList2 from "./../../Opportunity/Components/Pages/List2";
import OpportunityInvestmentList from "./../../Investor/Components/Pages/InvestmentList";
import OpportunityInvestmentUpdateList from "./../../Investor/Components/Pages/InvestmentUpdateList";
import DeveloperList from "./../../Developer/Components/Pages/List";
import DeveloperCompanyAdd from "./../../Developer/Components/Pages/CompanyAdd";
import DeveloperCompanyEdit from "./../../Developer/Components/Pages/CompanyEdit";
import DeveloperCompanyView from "./../../Developer/Components/Pages/CompanyView";
import WalletTransactionList from "./../../Wallet/Components/Pages/TransactionList";
import MessageList from "./../../Message/Components/Pages/ConversationList";
import NotificationList from "./../../Notification/Components/Pages/List";
import SupportTicketList from "./../../Support/Components/Pages/TicketList";
import SupportTicketAdd from "./../../Support/Components/Pages/TicketAdd";
import SupportTicketView from "./../../Support/Components/Pages/TicketView";
import Resources from "./../../Resource/index";
import ResourceMortgageCalculator from "./../../Resource/Components/Pages/MortgageCalculator";
import ResourceVirtualTourAdd from "./../../Resource/Components/Pages/VirtualTourAdd";
import ResourceVirtualTourView from "./../../Resource/Components/Pages/VirtualTourView";
import ResourceVirtualTourPayment from "./../../Resource/Components/Pages/VirtualTourPayment";
import ResourceArticleResearchPaperList from "./../../Resource/Components/Pages/ArticleResearchPaperList";
import ResourceArticleResearchPaperView from "./../../Resource/Components/Pages/ArticleResearchPaperView";
import ResourceEmailPhoneMarketingAdd from "./../../Resource/Components/Pages/EmailPhoneMarketingAdd";
import ResourceEmailPhoneMarketingPayment from "./../../Resource/Components/Pages/EmailPhoneMarketingPayment";
import BlogArticleList from "./../../Blog/Components/Pages/ArticleList";
import BlogArticleView from "./../../Blog/Components/Pages/ArticleView";
import Faq from "./Pages/Faq";
import About from "./Pages/About";
import Knowledge from "./Pages/Knowledge";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import Risk from "./Pages/Risk";
import Fees from "./Pages/Fees";
import NotFound from "./Pages/NotFound";
import { Context } from "./Context";
import DealRoom from "../../Opportunity/Components/Pages/DealRoom";
import ReferralList from "../../Investor/Components/Pages/ReferralList";
import SettlementList from "../../Investor/Components/Pages/SettlementList";

import DealRoomMain from "../../Opportunity/Components/Pages/DealRoomMain";
import DealRoomId from "../../Opportunity/Components/Pages/DealRoomId";
import PropertyPage from "../../Opportunity/Components/Pages/PropertyPage";
import Opportunities from "../../Opportunity/Components/Pages/Opportunities";
import Waitlist from "./Pages/waitlist";
import ZeroCarbon from "./Pages/ZeroCarbon";
import ProjectProgress from "../../Opportunity/Components/Pages/ProjectUpdate";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    Navigation.contextType = Context;
    this.state = {};
  }

  render() {
    return (
      <Router>
        <Layout>
          <Route
            render={({ location }) => (
              <TransitionGroup>
                <CSSTransition
                  key={location.pathname.split("/")[1]}
                  timeout={1000}
                  classNames="page-transition"
                  mountOnEnter={true}
                  unmountOnExit={true}
                >
                  <Switch>
                    <Route path="/" exact component={PropCrowdy} />
                    <Route path="/home" component={PropCrowdy} />
                    <Route path="/waitlist" exact component={Waitlist} />
                    <Route path="/carbon" exact component={ZeroCarbon} />
                    <Route path="/dashboard" component={UserDashboard} />
                    <Route path="/register" component={UserRegister} />
                    <Route path="/login" component={UserLogin} />
                    <Route path="/verify" component={UserVerify} />
                    <Route path="/forgot" component={UserForgot} />
                    <Route path="/logout" component={UserLogout} />
                    <Route path="/how" component={How} />
                    <Route path="/discover" component={OpportunityList} />
                    {/* <Route path="/opportunities" component={OpportunityList2} /> */}
                    <Route
                      path="/my-opportunities"
                      component={OpportunityList2}
                    />
                    <Route
                      path="/opportunity/create"
                      component={OpportunityAdd}
                    />
                    <Route
                      path="/opportunity/:id"
                      component={OpportunityView}
                    />
                    <Route
                      path="/project/progress/update/:id"
                      component={ProjectProgress}
                    />
                    {/* <Route path="/deal-room/" component={DealRoom} /> */}
                    {/* <Route path="/deal-room/:id" component={DealRoom} /> */}
                    <Route path="/deal-room/" component={DealRoomMain} exact />
                    <Route path="/deal-room/:id" component={DealRoomId} />
                    <Route path="/property" component={PropertyPage} />
                    {/* <Route path="/opportunities" component={Opportunities} /> */}
                    {/*the path can be changed later. */}
                    <Route
                      path="/fund/history"
                      component={OpportunityInvestmentList}
                    />
                    <Route path="/referrals" component={ReferralList} />
                    <Route
                      path="/referral-settlements"
                      component={SettlementList}
                    />
                    <Route
                      path="/fund/updates"
                      component={OpportunityInvestmentUpdateList}
                    />
                    <Route
                      path="/fund/raisers"
                      render={(props) => (
                        <OpportunityList2 {...{ props }} funded />
                      )}
                    />
                    <Route path="/developers" component={DeveloperList} />
                    <Route
                      path="/company/setup"
                      component={DeveloperCompanyAdd}
                    />
                    <Route
                      path="/company/details"
                      component={DeveloperCompanyEdit}
                    />

                    {/* <Route
                      path="/company-profile"
                      component={DeveloperCompanyEdit}
                    /> */}

                    <Route
                      path="/company/:id"
                      component={DeveloperCompanyView}
                    />
                    <Route
                      path="/wallet/transactions"
                      component={WalletTransactionList}
                    />
                    <Route path="/notifications" component={NotificationList} />
                    <Route path="/messages" component={MessageList} />
                    <Route path="/account/payment" component={UserAccount} />
                    <Route path="/account" component={UserAccount} />
                    <Route
                      path="/support/tickets"
                      component={SupportTicketList}
                    />
                    <Route
                      path="/support/new-ticket"
                      component={SupportTicketAdd}
                    />
                    <Route
                      path="/support/ticket/:id/:slug"
                      component={SupportTicketView}
                    />
                    <Route path="/resources" component={Resources} />
                    <Route
                      path="/resource/mortgage-calculator"
                      component={ResourceMortgageCalculator}
                    />
                    <Route
                      path="/resource/virtual-tour/create"
                      component={ResourceVirtualTourAdd}
                    />
                    <Route
                      path="/resource/virtual-tour/payment/:id"
                      component={ResourceVirtualTourPayment}
                    />
                    <Route
                      path="/resource/virtual-tour/:id"
                      component={ResourceVirtualTourView}
                    />
                    <Route
                      path="/resource/articles-research-papers"
                      component={ResourceArticleResearchPaperList}
                    />
                    <Route
                      path="/resource/article-research-paper/:id"
                      component={ResourceArticleResearchPaperView}
                    />
                    <Route
                      path="/resource/email-phone-marketing/payment/:id"
                      component={ResourceEmailPhoneMarketingPayment}
                    />
                    <Route
                      path="/resource/email-phone-marketing"
                      component={ResourceEmailPhoneMarketingAdd}
                    />
                    <Route path="/articles" component={BlogArticleList} />
                    <Route path="/article/:id" component={BlogArticleView} />
                    <Route path="/faq" component={Faq} />
                    <Route path="/about" exact component={About} />
                    <Route path="/knowledge" component={Knowledge} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/risk" component={Risk} />
                    <Route path="/fees" component={Fees} />
                    <Route path="/faq" component={Faq} />
                    <Route path="/buy/about" component={About} />
                    <Route path="/buy/knowledge" component={Knowledge} />
                    <Route path="/buy/terms" component={Terms} />
                    <Route path="/buy/privacy" component={Privacy} />
                    <Route path="/buy/fees" component={Fees} />
                    <Route path="/buy/faq" component={Faq} />
                    <Route component={NotFound} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
        </Layout>
      </Router>
    );
  }
}

export default Navigation;
