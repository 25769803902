import "./../../assets/style.css";
import React from "react";
import { Link } from "react-router-dom";
import Util from "./../../../App/Components/Utils";
import DateCountdown from "react-date-countdown-timer";
import Countdown from "react-countdown";
import ProgressBarCustom from "../Accessories/ProgressBarCustom";

export default class OpportunityListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opportunity: this.props.opportunity,
      today: new Date(),
      starting_date: new Date(this.props.opportunity.start_date),
      closing_date: new Date(this.props.opportunity.closing_date),
      closed: false,
    };
  }

  componentDidMount() {
    let opportunity = this.state.opportunity;
    const closed = this.state.today > this.state.starting_date && this.state.opportunity.investment_status === 2
    this.setState({ opportunity: opportunity, closed:closed });
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {

    if (
      this.state.today < this.state.starting_date ||
      this.state.today < this.state.closing_date ||
      this.state.opportunity.investment_status === 1
    ) {
      // Render a countdown
      return (
        <div className="countdown-timer">
          Time Left:<span id="tab"></span> {days}
          <span>Days &nbsp;</span> : {hours} <span> Hours &nbsp;</span> : &nbsp;
          {minutes}
          <span> Mins &nbsp;</span> : {seconds} <span> Secs </span>
        </div>
      );
    }
      return (
        <div className="countdown-timer" style={{backgroundColor:'#ada4a4', color:'#fff', bottom: "-36px", justifyContent: 'unset'}}>
          <div style={{marginLeft:'10px'}}> Closed </div>
        </div>
      )

  };
  render() {
    let percent =
      (parseFloat(this.state.opportunity.amount_raised || 0) /
        parseFloat(this.state.opportunity.amount)) *
      100;

    let progress =
      percent && percent < 1 ? percent.toFixed(2) : Math.floor(percent);
    return (
      <div className="opportunity">
        <Link
          to={{
            pathname: "/opportunity/" + this.state.opportunity.slug,
            state: { opportunity: this.state.opportunity },
          }}
          style={{
            backgroundImage:
              'url("' +
              (this.state.opportunity.media && this.state.opportunity.media[0]
                ? this.state.opportunity.media[0].url
                : "/assets/images/image-placeholder.png") +
              '")',
          }}
          className="image"
        >
            {!this.state.closed?(
                <div
                    style={{
                        color: "#fff",
                        fontSize: "14px",
                        width: "100%",
                        maxWidth: "107px",
                        height: "32px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#ff0000",
                        border: "1px solid #ffffff",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: "47px",
                        position: "absolute",
                        left: "14px",
                        top: "16px",
                    }}
                >
                    {this.state.today < this.state.starting_date
                        ? "Coming Soon"
                        : this.state.today >= this.state.starting_date &&
                        this.state.opportunity.investment_status === 1
                            ? "Open"
                            : this.state.today > this.state.starting_date &&
                            this.state.opportunity.investment_status === 2
                                ? "Closed"
                                : this.state.today > this.state.starting_date &&
                                this.state.opportunity.investment_status === 3
                                    ? "Cancelled"
                                    : this.state.today > this.state.starting_date &&
                                    this.state.opportunity.investment_status === 4
                                        ? "Completed"
                                        : ""}
                </div>
            ):null}
            {this.context.settings && this.context.settings.investments && this.context.settings.investments.investment_count_down?(
            <>
                {this.state.today < this.state.starting_date ? (
                    <Countdown
                        date={this.state.starting_date}
                        renderer={this.renderer}
                    />
                ) : (
                    this.state.today >= this.state.starting_date &&
                    this.state.today <= this.state.closing_date && (
                        <Countdown
                            date={this.state.closing_date}
                            renderer={this.renderer}
                        />
                    )
                )}
            </>):null
            }
            {this.state.closed?(
                <Countdown
                    date={this.state.starting_date}
                    renderer={this.renderer}
                />
            ):null}
          {/* <Countdown
            // date={this.state.opportunity.start_date + " 23:59:59"}
            date={this.state.starting_date}
            renderer={this.renderer}
          /> */}
        </Link>
        {/* <div className="goal-raised">
          <div className="percent">
            <div
              className="bar"
              style={{
                width:
                  (percent && percent < 1
                    ? percent.toFixed(2)
                    : Math.floor(percent)) + "%",
              }}
            >
              <span className="number">
                {percent && percent < 1
                  ? percent.toFixed(2)
                  : Math.floor(percent)}
                %
              </span>
            </div>
          </div>
          <div className="data">
            <div className="raised">
              Raised ₦
              {parseInt(this.state.opportunity.amount_raised || 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
            <div className="goal">
              Goal ₦
              {parseInt(this.state.opportunity.amount)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
        </div> */}

        {/* MY ADDITION STARTS HERE=============== */}
        <div
          style={{ padding: "0 5.44%", paddingBottom: this.state.closed?'30px':"73.75px" }}
          className="more_info"
        >
            {this.context && this.context.settings && this.context.settings.investments && this.context.settings.investments.show_goal && !this.state.closed?(
                <div className="raised_amount_display">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "end",
                            gap: "4%",
                        }}
                    >
                        <div
                            style={{
                                fontFamily: "ApercuProMediumNormal",
                                fontSize: "36px",
                                lineHeight: "56px",
                                letterSpacing: "-0.03em",
                            }}
                        >
                            ₦{" "}
                            {parseInt(this.state.opportunity.amount_raised || 0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                        <p
                            style={{
                                color: "#ff0000",
                                fontSize: "13px",
                                lineHeight: "16.16px",
                                marginBottom: "10px",
                            }}
                        >
                            Raised
                        </p>
                    </div>
                    <ProgressBarCustom
                        height={"8px"}
                        borderRadius={"4px"}
                        backgroundColor={"#00D7A3"}
                        color={"#fff"}
                        progress={progress}
                        parentStyles={{
                            padding: "2px",
                            margin: "12px 0",
                        }}
                    />

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3%",
                        }}
                    >
                        <p
                            style={{
                                backgroundColor: "#ff0000",
                                color: "#fff",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                lineHeight: "17.4px",
                                maxWidth: "50px",
                            }}
                        >
                            Goal
                        </p>
                        <div
                            style={{
                                fontFamily: "ApercuProMediumNormal",
                            }}
                        >
                            ₦{" "}
                            {parseInt(this.state.opportunity.amount)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                    </div>
                </div>
            ):null}

          <div className="other_texts">
            <Link
              to={{
                pathname: "/opportunity/" + this.state.opportunity.slug,
                state: { opportunity: this.state.opportunity },
              }}
              className="property_name"
            >
              {this.state.opportunity.title}
            </Link>
            <div className="investment_desc">
              <div className="investment_property">
                <p className="param">Minimum Investment</p>
                <p className="value">
                  ₦{" "}
                  {this.state.opportunity.min_investment
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
              <div className="investment_property">
                <p className="param">Return On Investment</p>
                <p className="value">
                  {this.state.opportunity.roi_percentage}%
                </p>
              </div>
            </div>
          </div>

          {this.props.opportunity.investment_status === 1 ? (
            <button
              className="pry_button"
              disabled={this.props.opportunity.investment_status === 1}
              style={{ width: "100%" }}
            >
              <Link
                to={{
                  pathname: "/opportunity/" + this.state.opportunity.slug,
                  state: { opportunity: this.state.opportunity },
                }}
                style={{ color: "#fff" }}
              >
                Invest Now
              </Link>
            </button>
          ) : (
            <div
              className="pry_button"
              style={{ width: "100%", opacity: "50%", color: "#fff", backgroundColor:'#ada4a4' }}
            >
              <Link style={{textDecoration:'none', color:'#fff', textTransform:'capitalize'}} to={`/project/progress/update/${this.props.opportunity.slug}`}>See Update</Link>
            </div>
          )}

          {/* <button
            className="pry_button"
            disabled={this.props.opportunity.investment_status === 1}
            style={{ width: "100%" }}
          >
            <Link
              to={{
                pathname: "/opportunity/" + this.state.opportunity.slug,
                state: { opportunity: this.state.opportunity },
              }}
              style={{ color: "#fff" }}
            >
              Invest Now
            </Link>
          </button> */}
        </div>
        {/* MY ADDITION ENDS HERE ============== */}
        {/* <Link
          to={{
            pathname: "/opportunity/" + this.state.opportunity.slug,
            state: { opportunity: this.state.opportunity },
          }}
          className="title"
        >
          {this.state.opportunity.title}
        </Link> */}
        {/* <div className="description">
          {Util.decodeHTMLEntities(
            this.state.opportunity.description.replace(/<\/?.*?>/gi, "")
          ).substring(0, 150) +
            (Util.decodeHTMLEntities(
              this.state.opportunity.description.replace(/<\/?.*?>/gi, "")
            ).length > 100
              ? "..."
              : "")}
        </div> */}
        {/* <div className="min-invest">
          Minimum Investment ₦
          {this.state.opportunity.min_investment
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div> */}
        {/* <div className="rating">
          <div className="stars">
            {Array.from({ length: 5 }).map((el, i) => (
              <i
                key={i}
                className={
                  "icon star las la-star" +
                  (i < this.state.opportunity.rating ? " shaded" : "")
                }
              />
            ))}
          </div>
        </div> */}
        {/* <div className="returns">
          {this.state.opportunity.roi_percentage}% returns in{" "}
          {this.state.opportunity.tenor} Months
        </div> */}
        {/* <Link
          to={{
            pathname: "/opportunity/" + this.state.opportunity.slug,
            state: { opportunity: this.state.opportunity },
          }}
          className="action"
        >
          Invest Now
        </Link> */}
      </div>
    );
  }
}
