import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Api from "./../../../App/Components/Utils/Api";
import Error from './../../../App/Components/Templates/Error';

const ProjectProgress = () => {
    const [updates, setUpdates] = useState([])
    const [loading, setLoading] = useState([])
    const [opportunity, setOpportunity] = useState()
    const [selected, setSelected] = useState()
    const [banner, setBanner] = useState()
    const [slug, setSlug] = useState('')

    const location = useLocation();
    let { id } = useParams()
    
    useEffect(()=>{
        const passedData = location?.state;
        setOpportunity(passedData)
        const slug = id??passedData?.slug
        getUpdates(slug).then()
    },[id])


    const getUpdates = async (id) => {
        setLoading(true)
        Api.fetch("opportunity.project.update", undefined ,{slug: id,}).then(({status, responseJSON}) => {
            const data = responseJSON.data
            const projectUpdates = data.updated
            setOpportunity(data.opportunity)
            setBanner(data.opportunity.property_images[0].url)
            if(projectUpdates.length){
                setSelected(projectUpdates[0])
            }
            setUpdates(projectUpdates)
        
            setLoading(false)
          }).catch((error) => {
            setLoading(false)
            console.log(error)
          })
    }

    const FormattedDateDay = (date) => {
        const dateString = date?new Date(date): new Date();
    
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
        }).format(dateString);

        return formattedDate
    }

    const FormattedDateMonth = (date) => {
        const dateString = date?new Date(date): new Date();
    
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
            month: 'long',
        }).format(dateString);

        return formattedDate
    }
    const FormattedDateYear = (date) => {
        const dateString = date?new Date(date): new Date();
    
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
            year: 'numeric'
        }).format(dateString);

        return formattedDate
    }
    return(
        <div id="project-progress" className="page">
            <div className="banner">
                <img src={banner} />
            </div>
            <div className="body">
                <div className="heading">{opportunity?.title??''}</div>
                <div style={{paddingTop:'30px'}} className="row">
                    <div className="col-md-3">
                        <div className="date">
                        {updates.map((update, index) => (
                            <div key={index} className={selected.id == update.id?"each-update active":"each-update"} onClick={() => {
                                setSelected(update)
                            }}>
                               <span className="day">{FormattedDateDay(update.created_at)}</span>
                               <sup className="month">{FormattedDateMonth(update.created_at)}</sup>
                               {/* <span>{FormattedDateYear(update.created_at)}</span> */}
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="description">
                            {selected?(
                                <div dangerouslySetInnerHTML={{__html:selected.description}}>

                                </div>
                            ):(
                                <div>
                                    No update yet
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProjectProgress