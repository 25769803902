import "../../assets/stylings/accessories_stylings/MinorCardsContainer_styling.css";

import AnchorLink from "react-anchor-link-smooth-scroll";

import rectangles from "../../assets/images/minor_card_rectangles.svg";
import { Link, useHistory } from "react-router-dom";
import Opportunities from './../Pages/Opportunities';

const MinorCardsContainer = ({ opportunity }) => {
  const history = useHistory();

  const handleDeveloperPageClick = () => {
    document.getElementById("heading_2").click();
  };

  const handleProceedToDealRoomClick = (
    slug,
    companyName,
    companyDescription,
    projectTitle,
    documents,
    media
  ) => {
    localStorage.setItem(
      "more_infos",
      JSON.stringify({
        companyName: companyName,
        companyDescription: companyDescription,
        projectTitle: projectTitle,
        projectDocuments: documents,
        projectPictures: media,
      })
    );

    history.push(`/deal-room/${slug}`);
  };

  return (
    <div className="minor_cards_container">
      <div style={{ background: "#E4FAFF" }} className="minor_card">
        <div className="card_texts">
          <p className="main_text">Property Developer</p>
          <p className="sub_text">Information</p>
        </div>
        <AnchorLink href="#heading_2">
          <button onClick={handleDeveloperPageClick} className="card_button">
            Visit Developer Page
          </button>
        </AnchorLink>

        <img src={rectangles} alt="" className="rectangles" />
      </div>
      <div style={{ background: "#DEE1E2" }} className="minor_card">
        <div className="card_texts">
          <p className="main_text">Enter Deal Room</p>
          <p className="sub_text">See All Supporting Documents</p>
        </div>
        {/* <Link to={"/deal-room"}> */}
        <button
          onClick={() =>
            handleProceedToDealRoomClick(
              opportunity.slug,
              opportunity.company.name,
              opportunity.company.description,
              opportunity.title,
              opportunity.deal_document,
              opportunity.media
            )
          }
          className="card_button"
        >
          Proceed To Deal Room
        </button>
        {/* </Link>zz */}

        <img src={rectangles} alt="" className="rectangles" />
      </div>
      <div style={{ background: "#DEE1E2" }} className="minor_card">
        <div className="card_texts">
          <p className="main_text">Enter Project Update</p>
          <p className="sub_text">See All Project Images</p>
        </div>
        {/* <Link to={"/deal-room"}> */}
        <Link state={Opportunities} to={`/project/progress/update/${opportunity.slug}`}>
            <button  className="card_button">
                Project Updates
            </button>
        </Link>
        {/* </Link>zz */}

        <img src={rectangles} alt="" className="rectangles" />
      </div>
    </div>
  );
};

export default MinorCardsContainer;
